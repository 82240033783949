import { useState } from "react";
import { Grid, Image, Header, Card } from 'semantic-ui-react'
import BackgroundSlider from "react-background-slider";
import Logo from './resources/logo.png'
import fbPage from './resources/fbPage.png'
import youtube from './resources/youtube.png'
import linkedin from './resources/linkedin.png';
import instagram from './resources/instagram.png'
import telegram from './resources/telegram.png'
import giftStatus from './resources/giftStatus.png'
import trainingFeedback from './resources/trainingFeedback.png'
import elearning from './resources/elearning.png'
import bg from './resources/bg.jpg'

function App() {
  const [backgroundURLs] = useState([bg])

  return (
    <div>
      <BackgroundSlider images={backgroundURLs} duration={100} transition={1} />
      <div className='login-form'>
        {/*
            Heads up! The styles below are necessary for the correct render of this example.
            You can do same with CSS, the main idea is that all the elements up to the `Grid`
            below must have a height of 100%.
          */}
        <style>{`
            body > div,
            body > div > div,
            body > div > div > div.login-form,
            .login-grid {
                height: 100%;
            }
            
            .login-grid-column {
                max-width: 60%;
            }
            
            .login-segment {
                background-color: #ffffff87!Important;
            }
          `}</style>

        <Grid centered className='login-grid' verticalAlign='middle'>
          <Grid.Column className='login-grid-column' textAlign='center'>
            <Image style={{ paddingTop: '15px', marginBottom: '-15px' }} src={Logo} size='medium' centered />
            <Header as='h1' textAlign='center' color='grey'>
              Sri Lanka
              <Header.Subheader>
                Official Web Portal
              </Header.Subheader>
            </Header>
            <Card.Group itemsPerRow={8} stackable>
              <Card href='https://www.facebook.com/HikvisionSriLankaOfficial/' target="_blank" >
                <Image src={fbPage} wrapped ui={false} />
                <Card.Content textAlign='center'>
                  <Card.Header>Official Facebook Page</Card.Header>
                  <Card.Meta>Hikvision Sri Lanka Official Facebook Page</Card.Meta>
                </Card.Content>
              </Card>
              <Card href='https://www.youtube.com/c/HikvisionSriLanka' target="_blank" >
                <Image src={youtube} wrapped ui={false} />
                <Card.Content textAlign='center' >
                  <Card.Header>Official YouTube Channel</Card.Header>
                  <Card.Meta>Hikvision Sri Lanka Official YouTube Channel</Card.Meta>
                </Card.Content>
              </Card>
              <Card href='https://www.linkedin.com/company/hikvisionsrilanka' target="_blank" >
                <Image src={linkedin} wrapped ui={false} />
                <Card.Content textAlign='center'>
                  <Card.Header>Official LinkedIn Page</Card.Header>
                  <Card.Meta>Hikvision Sri Lanka Official LinkedIn Page</Card.Meta>
                </Card.Content>
              </Card>
              <Card href='https://www.instagram.com/hikvisionsrilankaofficial/' target="_blank" >
                <Image src={instagram} wrapped ui={false} />
                <Card.Content textAlign='center'>
                  <Card.Header>Official Instagram Page</Card.Header>
                  <Card.Meta>Hikvision Sri Lanka Official Instagram Page</Card.Meta>
                </Card.Content>
              </Card>
              <Card href='https://t.me/HikvisionSriLanka' target="_blank" >
                <Image src={telegram} wrapped ui={false} />
                <Card.Content textAlign='center'>
                  <Card.Header>Official Telegram Channel</Card.Header>
                  <Card.Meta>Hikvision Sri Lanka Official Telegram Channel</Card.Meta>
                </Card.Content>
              </Card>
              <Card href='https://elearning.hikvision.com/en/' target="_blank" >
                <Image src={elearning} wrapped ui={false} />
                <Card.Content textAlign='center'>
                  <Card.Header>Hikvision eLearning</Card.Header>
                  <Card.Meta>Explore Hikvision eLearning Platform</Card.Meta>
                </Card.Content>
              </Card>
              <Card href='https://docs.google.com/forms/d/e/1FAIpQLSdCgZwmMjV-3kcpcVJGt7u8uOdu-yKAfeFt6OvrtFayQiB-8A/viewform?usp=sf_link' target="_blank">
                <Image src={trainingFeedback} wrapped ui={false} />
                <Card.Content textAlign='center'>
                  <Card.Header>Training Feedback</Card.Header>
                  <Card.Meta>Provide feedback on Hikvison Trainings</Card.Meta>
                </Card.Content>
              </Card>
              <Card href='https://gifts.hikvision.lk' target="_blank">
                <Image src={giftStatus} wrapped ui={false} />
                <Card.Content textAlign='center'>
                  <Card.Header>Gifts</Card.Header>
                  <Card.Meta>If you won gifts from Hikvision Trainings you can check the delivery status here</Card.Meta>
                </Card.Content>
              </Card>
            </Card.Group>
            <Header as='h4'>
              © 2022 portal.hikvision.lk
              <Header.Subheader>
                Hangzhou Hikvision Digital Technology Co., Ltd, Sri Lanka. All Rights Reserved.
              </Header.Subheader>
            </Header>

          </Grid.Column>

        </Grid>

      </div>
    </div>
  );

}

export default App;
